import { TOGGLE_MOBILE_SIDEBAR, TOGGLE_THEME_MODE } from './mutation-types'

export default {
    toggleThemeMode: ({ commit, state }) => {
        localStorage.setItem('themeMode', state.themeMode === 'light' ? 'dark' : 'light')

        commit(TOGGLE_THEME_MODE)
    },
    toggleMobileSidebar({ commit }) {
        commit(TOGGLE_MOBILE_SIDEBAR)
    },
}
