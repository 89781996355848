<template>
    <div
        :class="[
            'accordion__item',
            'accordion-item',
            `accordion-item_theme_${theme}`,
            {
                'accordion-item_state_active': isActive,
            },
        ]"
    >
        <div class="accordion-item__head" @click="toggle">
            <div class="accordion-item__icon-container">
                <div class="accordion-item__icon">
                    <div class="accordion-item__icon-horizontal"></div>
                    <div class="accordion-item__icon-vertical"></div>
                </div>
            </div>
            <div class="accordion-item__title">
                {{ title }}
            </div>
        </div>

        <transition
            name="accordion"
            @enter="enter"
            @leave="leave"
            @before-enter="beforeEnter"
            @before-leave="beforeLeave"
        >
            <div v-show="isActive" class="accordion-item__body">
                <div class="accordion-item__content">
                    <slot />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'AppAccordionItem',

    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        isActive: false,
    }),

    computed: {
        theme() {
            return this.$parent.theme
        },
    },

    mounted() {
        this.isActive = this.active
    },

    methods: {
        toggle() {
            this.$parent.$children.forEach((item) => {
                item.isActive = !item.isActive ? item.id === this.id : false
            })
        },
        beforeEnter(el) {
            el.style.height = '0'
        },
        enter(el) {
            el.style.height = `${el.scrollHeight}px`
        },
        beforeLeave(el) {
            el.style.height = `${el.scrollHeight}px`
        },
        leave(el) {
            el.style.height = '0'
        },
    },
}
</script>
