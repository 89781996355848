<template>
    <div
        :class="[
            'input-field',
            `input-field_theme_${theme}`,
            `input-field_type_${type}`,
            {
                'input-field_state_focus': isFocus,
                'input-field_state_disabled': isDisabled,
                'input-field_state_invalid': !!$slots.error,
            },
        ]"
    >
        <label v-if="label" class="input-field__label" :for="id">
            {{ label }}
        </label>
        <div class="input-field__input-wrapper">
            <input
                v-bind="privateProps"
                class="input-field__input"
                v-imask="mask"
                v-autofocus="autofocus"
                @focus="handleFocus"
                @blur="handleBlur"
                @input="handleInput"
                @accept="handleAccept"
                @complete="handleComplete"
                ref="input"
                data-test-id="input-field"
            />
            <div v-if="$slots.postfix" class="input-field__postfix">
                <slot name="postfix" />
            </div>

            <div v-if="$slots.hint" class="input-field__hint input-field-hint">
                <AppIcon class="input-field-hint__icon" name="question" :width="27" :height="27"></AppIcon>
                <div class="input-field-hint__message">
                    <slot name="hint" />
                </div>
            </div>

            <button
                v-if="type === 'password'"
                :class="[
                    'input-field__toggle-password',
                    {
                        'input-field__toggle-password_state_active': inputType === 'text',
                    },
                ]"
                type="button"
                @click="togglePassword"
            />
        </div>

        <div v-if="$slots.description" class="input-field__description">
            <slot name="description" />
        </div>

        <transition name="fade" mode="out-in">
            <div v-if="$slots.error" class="input-field__error">
                <slot name="error" />
            </div>
        </transition>
    </div>
</template>

<script>
const randomInt = () => {
    const s4 = () =>
        Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1)

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

export default {
    name: 'InputField',

    props: {
        theme: {
            type: String,
            default: 'krudon',
        },
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Number],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        mask: {
            type: Object,
            default: () => null,
        },
        id: {
            type: [Number, String],
            default: () => randomInt(),
        },
        debounce: {
            type: Number,
            default: () => null,
        },
    },

    data: () => ({
        inputType: 'text',
        isFocus: false,
        timeout: null,
    }),

    computed: {
        isDisabled() {
            return this.disabled
        },
        privateProps() {
            return {
                ...this.$attrs,
                ...this.$props,
                id: this.id,
                type: this.inputType,
            }
        },
    },

    watch: {
        autofocus(val) {
            if (val) {
                this.$refs.input.focus()
            }
        },
    },

    created() {
        this.inputType = this.type
    },

    methods: {
        handleAccept(e) {
            this.emitInput(e.detail.unmaskedValue)
        },
        handleComplete(e) {
            this.$emit('complete', e.detail.unmaskedValue)
        },
        handleFocus() {
            this.isFocus = true

            this.$emit('focus')
        },
        handleBlur() {
            this.isFocus = false

            this.$emit('blur')
        },
        handleInput(e) {
            if (this.mask && this.mask.mask) {
                return
            }

            this.emitInput(e.target.value)
        },
        emitInput(value) {
            if (!this.debounce) {
                this.$emit('input', value)

                return
            }

            if (this.timeout) {
                clearTimeout(this.timeout)
            }

            this.timeout = setTimeout(() => {
                this.$emit('input', value)
            }, this.debounce)
        },

        togglePassword() {
            this.inputType = this.inputType === 'password' ? 'text' : 'password'
        },
    },
}
</script>
