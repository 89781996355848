<template>
    <ul class="social-links">
        <li v-for="(link, key) in links" :key="key" class="social-links__item">
            <a class="social-links__link" target="_blank" :href="link.link"> {{ link.title }} </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'SocialLinks',

    data: () => ({
        links: [
            {
                title: 'instagram',
                link: 'https://www.instagram.com/bankvtb/',
            },
            {
                title: 'facebook',
                link: 'https://www.facebook.com/vtbrussia/',
            },
            {
                title: 'vtb-bank',
                link: 'https://www.vtb.ru',
            },
        ],
    }),
}
</script>
