<template>
    <portal to="modal">
        <transition name="fade-shrink-in" appear>
            <div
                :class="[
                    'modal',
                    `modal_theme_${theme}`,
                    {
                        modal_state_open: isOpen,
                    },
                ]"
            >
                <div class="modal__wrapper" @click="clickOutside && close()">
                    <div class="modal__container" @click.stop>
                        <button class="modal__close" @click="close">
                            <AppIcon width="32" height="32" name="close" />
                        </button>
                        <div class="modal__content">
                            <slot />
                        </div>
                    </div>
                </div>
                <slot name="circles" />
            </div>
        </transition>
    </portal>
</template>

<script>
export default {
    name: 'AppModal',

    model: {
        prop: 'active',
        event: 'input',
    },

    props: {
        theme: {
            type: String,
            default: 'crulas',
        },
        active: {
            type: Boolean,
            required: true,
        },
        clickOutside: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        isOpen() {
            return this.active
        },
    },

    watch: {
        active: {
            handler: function (value) {
                if (value) {
                    this.open()
                } else {
                    this.close()
                }
            },
            immediate: true,
        },
    },

    created() {
        document.addEventListener('keydown', this.handleEsc)
    },

    beforeDestroy() {
        document.removeEventListener('keydown', this.handleEsc)
    },

    methods: {
        open() {
            this.$emit('open')
        },
        close() {
            this.$emit('close')

            this.$emit('input', false)
        },
        handleEsc(e) {
            if (this.isOpen && e.keyCode === 27) {
                this.close()
            }
        },
    },
}
</script>
