<template>
    <div class="section section_theme_form">
        <div class="container container_theme_default">
            <h1 class="section__title">
                {{ $t(`${$options.name}.title`) }}
            </h1>
            <ValidationObserver ref="form" v-slot="{ handleSubmit }" class="section__form-container" tag="div">
                <form class="section__form form row" @submit.prevent="handleSubmit(submitForm)">
                    <div class="col col_size_3 col_md-size_4 col_sm-size_6 col_xs-size_12">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t(`${$options.name}.form.full_name.title`)"
                            class="form__field"
                            mode="eager"
                            rules="required"
                            tag="div"
                            vid="full_name"
                        >
                            <InputField
                                v-model="formData.full_name"
                                :label="$t(`${$options.name}.form.full_name.title`)"
                                :placeholder="$t(`${$options.name}.form.full_name.placeholder`)"
                                name="form-name"
                            >
                                <template v-if="errors[0]" slot="error">
                                    {{ errors[0] }}
                                </template>
                            </InputField>
                        </ValidationProvider>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t(`${$options.name}.form.email.title`)"
                            class="form__field"
                            mode="eager"
                            rules="required|email"
                            tag="div"
                            vid="email"
                        >
                            <InputField
                                v-model="formData.email"
                                :label="$t(`${$options.name}.form.email.title`)"
                                :placeholder="$t(`${$options.name}.form.email.placeholder`)"
                                name="form-email"
                            >
                                <template v-if="errors[0]" slot="error">
                                    {{ errors[0] }}
                                </template>
                            </InputField>
                        </ValidationProvider>
                    </div>
                    <div class="col col_size_6 col_md-size_5 col_sm-size_6 col_xs-size_12">
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t(`${$options.name}.form.text.title`)"
                            class="form__field"
                            mode="eager"
                            rules="required|min:8"
                            tag="div"
                            vid="text"
                        >
                            <TextAreaField
                                v-model="formData.text"
                                :label="$t(`${$options.name}.form.text.title`)"
                                :placeholder="$t(`${$options.name}.form.text.placeholder`)"
                                name="form-message"
                                rows="6"
                            >
                                <template v-if="errors[0]" slot="error">
                                    {{ errors[0] }}
                                </template>
                            </TextAreaField>
                        </ValidationProvider>
                    </div>
                    <div class="form__submit-container col col_size_3 col_sm-size_12 col_xs-size_12">
                        <button
                            :disabled="formState === 'in-process'"
                            class="form__submit button button_theme_form-submit"
                            type="submit"
                        >
                            <span class="button__text">{{
                                formState === 'in-process'
                                    ? $t(`${$options.name}.sending`)
                                    : $t(`${$options.name}.done`)
                            }}</span>
                            <span class="button__icon">
                                <AppIcon :height="12" :width="12" color="#fff" name="send" />
                            </span>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { extend } from 'vee-validate'
import InputField from '@/components/Base/Form/InputField'
import TextAreaField from '@/components/Base/Form/TextAreaField'
import { email, min, required } from 'vee-validate/dist/rules.umd.js'

extend('email', email)
extend('required', required)
extend('min', min)

export default {
    name: 'HomeFormSection',

    components: {
        InputField,
        TextAreaField,
    },

    data() {
        return {
            formState: null,
            formData: {
                full_name: null,
                email: null,
                text: null,
            },
            formMessage: null,
        }
    },

    methods: {
        setFormMessage(message, type) {
            this.formMessage = message
            this.$toasted.global[type]({ message })
        },
        resetForm() {
            this.formData.full_name = null
            this.formData.email = null
            this.formData.text = null

            this.$refs.form.reset()
        },
        async submitForm() {
            this.formState = 'in-process'

            axios
                .post('https://dm.paymo.ru/api/v1/support/help', this.formData)
                .then(() => {
                    this.formState = null
                    this.formData = {
                        full_name: null,
                        email: null,
                        text: null,
                    }
                    this.$refs.form.reset()

                    this.setFormMessage(this.$t(`${this.$options.name}.message.success`), 'success')

                    this.resetForm()
                })
                .catch((error) => {
                    this.formState = null

                    if (error.response === undefined) {
                        this.setFormMessage(this.$t(`${this.$options.name}.message.error`), 'error')

                        return
                    }

                    const { status, data } = error.response

                    if (status === 400 && data.errors) {
                        this.$refs.form.setErrors(data.errors)

                        this.setFormMessage(data.message[0], 'error')
                    } else {
                        this.setFormMessage(this.$t(`${this.$options.name}.message.error`), 'error')
                    }
                })
        },
    },
}
</script>
