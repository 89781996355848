const getThemeMode = () => {
    const theme = localStorage.getItem('themeMode')

    if (theme) {
        return theme
    }

    return !!window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
}

export default {
    themeMode: getThemeMode(),
    isMobileSidebarOpen: false,
}
