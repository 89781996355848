import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n, { loadLocaleMessagesAsync } from '@/plugins/i18n'
import { setDocumentDirectionPerLocale, setDocumentLang, setDocumentTitle } from '@/utils/i18n/document'
import { localize } from 'vee-validate'

const { locale } = i18n

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: locale,
    },
    {
        path: '/:locale',
        component: { render: (c) => c('router-view') },
        children: [
            {
                path: '',
                name: 'Home',
                component: Home,
            },
            {
                path: 'terms-and-conditions',
                name: 'TermsAndConditions',
                component: () => import(/* webpackChunkName: "about" */ '../views/TermsAndConditions.vue'),
            },
            {
                path: 'privacy-and-policy',
                name: 'PrivacyAndPolicy',
                component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyAndPolicy.vue'),
            },
            {
                path: '*',
                redirect: 'Home',
            },
        ],
    },
]

const router = new VueRouter({
    mode: process.env.MODE === 'production' ? 'history' : 'hash',
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.params.locale === from.params.locale) {
        next()

        return
    }

    let { locale } = to.params

    if (locale === undefined) {
        locale = 'en'
    }

    localize(locale)

    loadLocaleMessagesAsync(locale).then(() => {
        setDocumentLang(locale)

        setDocumentDirectionPerLocale(locale)

        setDocumentTitle(i18n.t('app.title'))
    })

    next()
})

export default router
