<template>
    <div class="section section_theme_security-images">
        <div class="container container_theme_default">
            <div class="section__images">
                <div class="section__image-container">
                    <AppIcon
                        class="section__image"
                        :key="isThemeModeDark"
                        name="masterCard"
                        color="#716F85"
                        height="37"
                        width="103"
                    />
                </div>
                <div class="section__image-container">
                    <AppIcon
                        class="section__image"
                        :key="isThemeModeDark"
                        name="pci"
                        color="#716F85"
                        height="37"
                        width="103"
                    />
                </div>
                <div class="section__image-container">
                    <AppIcon
                        class="section__image"
                        :key="isThemeModeDark"
                        name="visa"
                        color="#716F85"
                        height="41"
                        width="95"
                    />
                </div>
                <div class="section__image-container">
                    <AppIcon
                        class="section__image"
                        :key="isThemeModeDark"
                        name="thawte"
                        color="#716F85"
                        height="38"
                        width="155"
                    />
                </div>
                <div class="section__image-container">
                    <AppIcon
                        class="section__image"
                        :key="isThemeModeDark"
                        name="mir"
                        color="#716F85"
                        height="44"
                        width="106"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'HomeSecurityImagesSection',

    computed: {
        ...mapGetters(['isThemeModeDark']),
    },
}
</script>
