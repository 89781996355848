<template>
    <div>
        <div class="container container_theme_default">
            <SocialLinks />
            <div class="footer">
                <div class="footer__row">
                    <div class="footer__col footer__col_name_market-links">
                        <MarketLinks location="footer" />
                    </div>
                    <div class="footer__col footer__col_name_nav">
                        <div class="footer__nav">
                            <a
                                href="https://paymo.ru/personal-data-agreement/"
                                class="footer__nav-link"
                                target="_blank"
                            >
                                {{ $t(`${$options.name}.privacyPolicy`) }}
                            </a>
                            <a
                                class="footer__nav-link"
                                :href="`https://dm.paymo.ru/static/files/terms_of_service_${$i18n.locale}.pdf`"
                                target="_blank"
                            >
                                {{ $t(`${$options.name}.terms`) }}
                            </a>
                        </div>
                    </div>
                    <div class="footer__col footer__col_name_copyright">
                        <div class="footer__copyright">
                            <span class="footer__copyright-text">{{ $t(`${$options.name}.poweredBy`) }}</span>
                            <AppIcon
                                class="footer__copyright-icon"
                                name="paymo"
                                width="94"
                                height="16"
                                color="#716F85"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SocialLinks from '@/components/SocialLinks'
import MarketLinks from '@/components/MarketLinks'

export default {
    name: 'AppFooter',

    components: {
        SocialLinks,
        MarketLinks,
    },

    data: () => ({}),
}
</script>
