import { TOGGLE_MOBILE_SIDEBAR, TOGGLE_THEME_MODE } from '@/store/mutation-types'

export default {
    [TOGGLE_THEME_MODE]: (state) => {
        state.themeMode = state.themeMode === 'light' ? 'dark' : 'light'
    },
    [TOGGLE_MOBILE_SIDEBAR]: (state) => {
        state.isMobileSidebarOpen = !state.isMobileSidebarOpen
    },
}
