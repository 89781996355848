<template>
    <vue-scroll @handle-scroll="handleScroll" ref="vs">
        <AppHeader class="layout__header" :sticky="isHeaderSticky" />
        <div class="layout__body">
            <HeroSection />
            <CtaWatchVideo />
            <BenefitsSection />
            <InterfaceSection />
            <SchemeSection />
            <SecuritySection />
            <SecurityImagesSection />
            <SecurityListSection />
            <FlagsSection />
            <FaqSection />
            <FormSection />
        </div>
        <AppFooter class="layout__footer" />
    </vue-scroll>
</template>

<script>
import vueScroll from 'vuescroll'
import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter'
import HeroSection from '@/components/Home/HeroSection'
import BenefitsSection from '@/components/Home/BenefitsSection'
import InterfaceSection from '@/components/Home/InterfaceSection'
import SchemeSection from '@/components/Home/SchemeSection'
import SecuritySection from '@/components/Home/SecuritySection'
import SecurityImagesSection from '@/components/Home/SecurityImagesSection'
import SecurityListSection from '@/components/Home/SecurityListSection'
import FlagsSection from '@/components/Home/FlagsSection'
import FaqSection from '@/components/Home/FaqSection'
import FormSection from '@/components/Home/FormSection'
import CtaWatchVideo from '@/components/Home/CtaWatchVideoSection'

export default {
    name: 'Home',
    components: {
        vueScroll,
        AppHeader,
        AppFooter,
        BenefitsSection,
        InterfaceSection,
        SchemeSection,
        SecuritySection,
        HeroSection,
        CtaWatchVideo,
        FaqSection,
        FlagsSection,
        SecurityImagesSection,
        SecurityListSection,
        FormSection,
    },
    data: () => ({
        isHeaderSticky: false,
        intersectionOptions: {
            root: null,
            rootMargin: '0px 0px 0px 0px',
            threshold: [0.25, 0.75],
        },
        currentSection: 'home',
        direction: null,
        debounce: 200,
        timeout: null,
    }),
    methods: {
        scrollTo(el) {
            this.$refs['vs'].scrollIntoView(el, 800)
        },
        viewHandler(section) {
            if (this.timeout) {
                clearTimeout(this.timeout)
            }

            this.timeout = setTimeout(() => {
                this.currentSection = section
            }, this.debounce)
        },
        onWaypoint(el) {
            const { going, direction } = el

            if (direction) {
                if (direction === this.$waypointMap.DIRECTION_TOP && going === 'in') {
                    this.viewHandler(el.el.id)
                } else if (direction === this.$waypointMap.DIRECTION_BOTTOM && going === 'in') {
                    this.viewHandler(el.el.id)
                }
            }
        },
        handleScroll(e) {
            if (e.scrollTop > 30 && !this.isHeaderSticky) {
                this.isHeaderSticky = true
            } else if (e.scrollTop < 30 && this.isHeaderSticky) {
                this.isHeaderSticky = false
            }
        },
    },
}
</script>
