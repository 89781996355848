<template>
    <div class="section section_theme_benefits">
        <div class="container container_theme_default">
            <div class="section__item">
                <h1 class="section__title animated fade-in-up" v-in-viewport="{ margin: '-100px 0px', once: true }">
                    {{ $t(`${$options.name}.title`) }}
                </h1>
                <div
                    class="section__image-container animated fade-in-up"
                    v-in-viewport="{ margin: '-100px 0px', once: true }"
                >
                    <img class="section__image" alt="d" :src="require('@/assets/images/phones/phone-4.png')" />
                </div>
                <div class="section__content">
                    <div class="row">
                        <div class="col col_size_6 col_xs-size_12">
                            <ul class="list">
                                <li
                                    class="list__item list-item animated fade-in-up"
                                    v-in-viewport="{ margin: '-100px 0px', once: true }"
                                >
                                    <div class="list-item__icon">
                                        <AppIcon color="#049fff" name="shield" :width="14" :height="14" />
                                    </div>
                                    <div class="list-item__content">
                                        <div class="list-item__title">{{ $t(`${$options.name}.safeTransfers`) }}</div>
                                        <div class="list-item__text">
                                            {{ $t(`${$options.name}.guarantee`) }}
                                        </div>
                                    </div>
                                </li>
                                <li
                                    class="list__item list-item animated fade-in-up"
                                    v-in-viewport="{ margin: '-100px 0px', once: true }"
                                >
                                    <div class="list-item__icon">
                                        <AppIcon color="#049fff" name="like" :width="14" :height="14" />
                                    </div>
                                    <div class="list-item__content">
                                        <div class="list-item__title">{{ $t(`${$options.name}.userFriendly`) }}</div>
                                        <div class="list-item__text">
                                            {{ $t(`${$options.name}.intuitive`) }}
                                        </div>
                                    </div>
                                </li>
                                <li
                                    class="list__item list-item animated fade-in-up"
                                    v-in-viewport="{ margin: '-100px 0px', once: true }"
                                >
                                    <div class="list-item__icon">
                                        <AppIcon color="#049fff" name="chartLine" :width="14" :height="14" />
                                    </div>
                                    <div class="list-item__content">
                                        <div class="list-item__title">
                                            {{ $t(`${$options.name}.transparency`) }}
                                        </div>
                                        <div class="list-item__text">
                                            {{ $t(`${$options.name}.commission`) }}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col col_size_6 col_xs-size_12">
                            <ul class="list">
                                <li
                                    class="list__item list-item animated fade-in-up"
                                    v-in-viewport="{ margin: '-100px 0px', once: true }"
                                >
                                    <div class="list-item__icon">
                                        <AppIcon color="#049fff" name="cloudCheck" :width="14" :height="14" />
                                    </div>
                                    <div class="list-item__content">
                                        <div class="list-item__title">
                                            {{ $t(`${$options.name}.instant`) }}
                                        </div>
                                        <div class="list-item__text">
                                            {{ $t(`${$options.name}.processing`) }}
                                        </div>
                                    </div>
                                </li>
                                <li
                                    class="list__item list-item animated fade-in-up"
                                    v-in-viewport="{ margin: '-100px 0px', once: true }"
                                >
                                    <div class="list-item__icon">
                                        <AppIcon color="#049fff" name="cloudUpload" :width="14" :height="14" />
                                    </div>
                                    <div class="list-item__content">
                                        <div class="list-item__title">
                                            {{ $t(`${$options.name}.money`) }}
                                        </div>
                                        <div class="list-item__text">
                                            {{ $t(`${$options.name}.add`) }}
                                        </div>
                                    </div>
                                </li>
                                <li
                                    class="list__item list-item animated fade-in-up"
                                    v-in-viewport="{ margin: '-100px 0px', once: true }"
                                >
                                    <div class="list-item__icon">
                                        <AppIcon color="#049fff" name="info" :width="14" :height="14" />
                                    </div>
                                    <div class="list-item__content">
                                        <div class="list-item__title">
                                            {{ $t(`${$options.name}.support`) }}
                                        </div>
                                        <div class="list-item__text">
                                            {{ $t(`${$options.name}.supportDescription`) }}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeBenefitsSection',
}
</script>
