<template>
    <kinesis-container class="section section_theme_flags">
        <div class="section__container container container_theme_default">
            <h1 class="section__title animated fade-in-up" v-in-viewport="{ margin: '-100px 0px', once: true }">
                {{ $t(`${$options.name}.title`) }}
            </h1>
            <div
                class="section__button-container animated fade-in-up"
                v-in-viewport="{ margin: '-100px 0px', once: true }"
            >
                <button class="button button_theme_flag hover" @click="showCountries = !showCountries">
                    {{ $t(`${$options.name}.completeList`) }}
                </button>
            </div>
            <div class="section__text animated fade-in-up" v-in-viewport="{ margin: '-100px 0px', once: true }">
                {{ $t(`${$options.name}.money`) }}
            </div>
        </div>
        <div class="section__flags">
            <ul class="images-parallax_theme_flags">
                <li
                    class="images-parallax__item"
                    :style="{
                        left: `${item.left}vw`,
                        top: `${item.top}%`,
                    }"
                    v-for="(item, _, index) in computedFlags"
                    :key="item.id"
                >
                    <kinesis-element :key="index" :strength="genRand(0, 100, 0)">
                        <div class="images-parallax__image-wrapper">
                            <img
                                :style="{
                                    filter: `blur(${item.blur}px)`,
                                }"
                                :width="item.size"
                                class="images-parallax__image animated fade-in-up"
                                v-in-viewport="{ margin: '-100px 0px', once: true }"
                                alt=""
                                :src="item.src"
                            />
                        </div>
                    </kinesis-element>
                </li>
            </ul>
        </div>
        <AppModal v-if="showCountries" v-model="showCountries" theme="asura">
            <CountriesList />
            <Circles slot="circles" theme="light-modal" />
        </AppModal>
    </kinesis-container>
</template>

<script>
import AppModal from '@/components/Base/AppModal'
import CountriesList from '@/components/Home/CountriesList'
import Circles from '@/components/Circles/Circles'

export default {
    name: 'HomeFlagsSection',
    components: {
        AppModal,
        CountriesList,
        Circles,
    },
    data() {
        return {
            showCountries: false,
            sizes: [35, 67, 16, 19, 24],
            blur: [1, 2, 3, 4, 0, 0, 0],
            flags: [
                {
                    id: 0,
                    src: require('@/assets/images/flags/de.svg'),
                },
                {
                    id: 2,
                    src: require('@/assets/images/flags/fr.svg'),
                },

                {
                    id: 4,
                    src: require('@/assets/images/flags/gb.svg'),
                },
                {
                    id: 5,
                    src: require('@/assets/images/flags/az.svg'),
                },
                {
                    id: 6,
                    src: require('@/assets/images/flags/it.svg'),
                },
                {
                    id: 7,
                    src: require('@/assets/images/flags/es.svg'),
                },
                {
                    id: 8,
                    src: require('@/assets/images/flags/cz.svg'),
                },
                {
                    id: 9,
                    src: require('@/assets/images/flags/es.svg'),
                },
                {
                    id: 10,
                    src: require('@/assets/images/flags/fr.svg'),
                },
                {
                    id: 11,
                    src: require('@/assets/images/flags/gb.svg'),
                },
                {
                    id: 12,
                    src: require('@/assets/images/flags/pl.svg'),
                },
                {
                    id: 13,
                    src: require('@/assets/images/flags/ie.svg'),
                },
                {
                    id: 15,
                    src: require('@/assets/images/flags/nr.svg'),
                },
                {
                    id: 16,
                    src: require('@/assets/images/flags/pl.svg'),
                },
                {
                    id: 17,
                    src: require('@/assets/images/flags/tr.svg'),
                },
                {
                    id: 18,
                    src: require('@/assets/images/flags/ae.svg'),
                },
                {
                    id: 19,
                    src: require('@/assets/images/flags/tj.svg'),
                },
                {
                    id: 20,
                    src: require('@/assets/images/flags/sk.svg'),
                },
                {
                    id: 21,
                    src: require('@/assets/images/flags/sm.svg'),
                },
                {
                    id: 22,
                    src: require('@/assets/images/flags/ru.svg'),
                },
            ],
        }
    },
    computed: {
        computedFlags() {
            const newFlags = []

            this.flags.forEach((f, index) => {
                const size = this.randomItem(index)

                newFlags.push({
                    ...f,
                    ...size,
                })
            })

            return newFlags
        },
    },
    methods: {
        genRand(min, max, decimalPlaces) {
            const rand = Math.random() * (max - min) + min
            const power = Math.pow(10, decimalPlaces)

            return Math.floor(rand * power) / power
        },
        randomItem(index) {
            const coficentSizes = Math.floor((this.sizes.length / index || 3.32) * index || -1.2)
            const coficentBlur = Math.floor((this.blur.length / coficentSizes) * this.blur.length)
            const size = this.sizes[Math.floor(Math.random() * coficentSizes)]
            const blur = this.blur[Math.floor(Math.random() * coficentBlur)]
            const top = this.genRand(0, 130, 0)
            const left = this.genRand(0, 100, 0)

            return {
                size,
                blur,
                left,
                top,
            }
        },
    },
}
</script>
