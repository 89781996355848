<template>
    <ul class="language-switcher">
        <li class="language-switcher__item" :value="locale.code" v-for="locale in locales" :key="locale.code">
            <button
                class="button button_theme_language-switcher language-switcher__button"
                :class="$i18n.locale === locale.code && `button_theme_language-switcher_active`"
                @click="changeLocale(locale.code)"
            >
                {{ locale.name }}
            </button>
        </li>
    </ul>
</template>

<script>
import { getSupportedLocales } from '@/utils/i18n/supported-locales'

export default {
    name: 'LanguageSwitcher',

    data: () => ({
        locales: getSupportedLocales(),
    }),

    methods: {
        changeLocale(locale) {
            if (locale === this.$i18n.locale) {
                return
            }

            this.$router.push(`/${locale}`)
        },
    },
}
</script>
