<template>
    <div class="countries">
        <div class="countries__title">{{ $t(`${$options.name}.title`) }}</div>
        <div class="countries__region region" v-for="(region, key) in countries" :key="key">
            <div class="region__title">{{ $t(`${$options.name}.${key}`) }}</div>
            <div class="region__countries">
                <div class="region__country" v-for="country in region" :key="country">
                    {{ $t(`${$options.name}.${country}`) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import countries from '@/utils/countries'

export default {
    name: 'Countries',

    computed: {
        countries: () => countries,
    },
}
</script>
