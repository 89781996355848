import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/global-components'
import '@/plugins'
// import 'aos/dist/aos.css'
import '@/directives'
import i18n from '@/plugins/i18n'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
