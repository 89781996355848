<template>
    <kinesis-container class="section section_theme_hero">
        <div class="container container_theme_default">
            <div class="section__row">
                <div class="section__col section__col_name_text">
                    <div
                        class="section__subtitle animated fade-in-left"
                        v-in-viewport="{ margin: '-100px 0px', once: true }"
                    >
                        {{ $t(`${$options.name}.aboutApp`) }}
                    </div>
                    <h1 class="section__title">
                        <b>{{ $t(`${$options.name}.title.check`) }}</b>
                        &nbsp;
                        <span>{{ $t(`${$options.name}.title.newService`) }}</span>
                        <br />
                        <span>{{ $t(`${$options.name}.title.forInternationalTransfers`) }}</span>
                    </h1>
                    <div class="section__text">
                        {{ $t(`${$options.name}.content`) }}
                    </div>
                    <MarketLinks class="section__market-links" location="body" />
                </div>
                <div
                    class="section__col section__col_name_images animated fade-in-up"
                    v-in-viewport="{ margin: '-100px 0px', once: true }"
                >
                    <div class="images-parallax_theme_hero">
                        <div class="images-parallax__image-wrapper">
                            <kinesis-element :strength="75">
                                <img
                                    class="images-parallax__image"
                                    alt=""
                                    :src="require('@/assets/images/phones/phone-3.png')"
                                />
                            </kinesis-element>
                        </div>
                        <div class="images-parallax__image-wrapper">
                            <kinesis-element :strength="45">
                                <img
                                    class="images-parallax__image"
                                    alt=""
                                    :src="require('@/assets/images/phones/phone-2.png')"
                                />
                            </kinesis-element>
                        </div>
                        <div class="images-parallax__image-wrapper">
                            <kinesis-element :strength="25">
                                <img
                                    class="images-parallax__image"
                                    alt=""
                                    :src="require('@/assets/images/phones/phone-1.png')"
                                />
                            </kinesis-element>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Circles class="section__circles" theme="primary" />
    </kinesis-container>
</template>

<script>
import Circles from '@/components/Circles/Circles'
import MarketLinks from '@/components/MarketLinks'

export default {
    name: 'HomeHeroImage',

    components: {
        Circles,
        MarketLinks,
    },
}
</script>
