<template>
    <div class="section section_theme_faq">
        <div class="container container_theme_default">
            <div class="section__row">
                <div class="section__col section__col_name_title">
                    <h1 class="section__title animated fade-in-up" v-in-viewport="{ margin: '-100px 0px', once: true }">
                        {{ $t(`${$options.name}.title`) }}
                    </h1>
                </div>
                <div class="section__col section__col_name_questions">
                    <AppAccordion theme="tribe">
                        <AppAccordionItem
                            v-for="(_, i) in $t(`${$options.name}.questions`)"
                            :key="`accordion-item-${i}`"
                            :id="`accordion-item-${i}`"
                            :title="$t(`${$options.name}.questions.${i}.title`)"
                        >
                            {{ $t(`${$options.name}.questions.${i}.text`) }}
                        </AppAccordionItem>
                    </AppAccordion>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppAccordion from '@/components/Base/AppAccordion/AppAccordion'
import AppAccordionItem from '@/components/Base/AppAccordion/AppAccordionItem'

export default {
    name: 'HomeFaqSection',

    components: {
        AppAccordion,
        AppAccordionItem,
    },
}
</script>
