import Vue from 'vue'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import en from 'vee-validate/dist/locale/en.json'

localize({
    ru,
    en,
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
