var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'input-field',
        "input-field_type_textarea",
        ("input-field_theme_" + _vm.theme),
        ("input-field_name_" + _vm.name),
        {
            'input-field_state_focus': _vm.isFocus,
            'input-field_state_disabled': _vm.isDisabled,
            'input-field_state_invalid': !!_vm.$slots.error,
        } ]},[(_vm.label)?_c('label',{staticClass:"input-field__label",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"input-field__input-wrapper"},[_c('textarea',_vm._b({directives:[{name:"autofocus",rawName:"v-autofocus",value:(_vm.autofocus),expression:"autofocus"}],ref:"input",staticClass:"input-field__input",on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"input":_vm.handleInput}},'textarea',_vm.privateProps,false)),_vm._v(" "),(_vm.$slots.postfix)?_c('div',{staticClass:"input-field__postfix"},[_vm._t("postfix")],2):_vm._e(),(_vm.$slots.hint)?_c('div',{staticClass:"input-field__hint input-field-hint"},[_c('AppIcon',{staticClass:"input-field-hint__icon",attrs:{"name":"question","width":27,"height":27}}),_c('div',{staticClass:"input-field-hint__message"},[_vm._t("hint")],2)],1):_vm._e()]),(_vm.$slots.description)?_c('div',{staticClass:"input-field__description"},[_vm._t("description")],2):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.$slots.error)?_c('div',{staticClass:"input-field__error"},[_vm._t("error")],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }