var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section section_theme_form"},[_c('div',{staticClass:"container container_theme_default"},[_c('h1',{staticClass:"section__title"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.name) + ".title")))+" ")]),_c('ValidationObserver',{ref:"form",staticClass:"section__form-container",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"section__form form row",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"col col_size_3 col_md-size_4 col_sm-size_6 col_xs-size_12"},[_c('ValidationProvider',{staticClass:"form__field",attrs:{"name":_vm.$t(((_vm.$options.name) + ".form.full_name.title")),"mode":"eager","rules":"required","tag":"div","vid":"full_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputField',{attrs:{"label":_vm.$t(((_vm.$options.name) + ".form.full_name.title")),"placeholder":_vm.$t(((_vm.$options.name) + ".form.full_name.placeholder")),"name":"form-name"},model:{value:(_vm.formData.full_name),callback:function ($$v) {_vm.$set(_vm.formData, "full_name", $$v)},expression:"formData.full_name"}},[(errors[0])?_c('template',{slot:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],2)]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__field",attrs:{"name":_vm.$t(((_vm.$options.name) + ".form.email.title")),"mode":"eager","rules":"required|email","tag":"div","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputField',{attrs:{"label":_vm.$t(((_vm.$options.name) + ".form.email.title")),"placeholder":_vm.$t(((_vm.$options.name) + ".form.email.placeholder")),"name":"form-email"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}},[(errors[0])?_c('template',{slot:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],2)]}}],null,true)})],1),_c('div',{staticClass:"col col_size_6 col_md-size_5 col_sm-size_6 col_xs-size_12"},[_c('ValidationProvider',{staticClass:"form__field",attrs:{"name":_vm.$t(((_vm.$options.name) + ".form.text.title")),"mode":"eager","rules":"required|min:8","tag":"div","vid":"text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaField',{attrs:{"label":_vm.$t(((_vm.$options.name) + ".form.text.title")),"placeholder":_vm.$t(((_vm.$options.name) + ".form.text.placeholder")),"name":"form-message","rows":"6"},model:{value:(_vm.formData.text),callback:function ($$v) {_vm.$set(_vm.formData, "text", $$v)},expression:"formData.text"}},[(errors[0])?_c('template',{slot:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],2)]}}],null,true)})],1),_c('div',{staticClass:"form__submit-container col col_size_3 col_sm-size_12 col_xs-size_12"},[_c('button',{staticClass:"form__submit button button_theme_form-submit",attrs:{"disabled":_vm.formState === 'in-process',"type":"submit"}},[_c('span',{staticClass:"button__text"},[_vm._v(_vm._s(_vm.formState === 'in-process' ? _vm.$t(((_vm.$options.name) + ".sending")) : _vm.$t(((_vm.$options.name) + ".done"))))]),_c('span',{staticClass:"button__icon"},[_c('AppIcon',{attrs:{"height":12,"width":12,"color":"#fff","name":"send"}})],1)])])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }