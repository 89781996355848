<template>
    <div class="section section_theme_cta-video container_theme_parallax-container">
        <h1
            class="section__title animated fade-in-up"
            v-in-viewport="{ margin: '-100px 0px', once: true }"
            @click="showVideo = !showVideo"
        >
            {{ $t(`${$options.name}.title`) }}
        </h1>
        <Circles theme="light" class="section__circles" />
        <AppModal v-if="showVideo" v-model="showVideo">
            <div class="video video_theme_presentation">
                <video
                    class="video__player"
                    :src="require('@/assets/video/dm-presentation.mp4')"
                    :poster="require('@/assets/images/dm-presentation-preview.png')"
                    autoplay
                    controls
                ></video>
            </div>
        </AppModal>
    </div>
</template>

<script>
import AppModal from '@/components/Base/AppModal'
import Circles from '@/components/Circles/Circles'

export default {
    name: 'HomeCtaWatchVideoSection',

    components: {
        Circles,
        AppModal,
    },

    data() {
        return {
            showVideo: false,
        }
    },
}
</script>
