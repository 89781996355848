<template>
    <div class="preloader" v-if="isLoading">Loading...</div>
    <div class="wrapper" v-else>
        <div class="layout layout_theme_fleton">
            <div class="layout__wrapper">
                <router-view />
            </div>
        </div>
        <BackgroundLines />
        <ThemeSwitcher />
        <portal-target name="modal" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from '@/plugins/eventBus'
import BackgroundLines from '@/components/BackgroundLines'
import ThemeSwitcher from '@/components/ThemeSwitcher'

export default {
    name: 'App',

    components: {
        BackgroundLines,
        ThemeSwitcher,
    },
    data() {
        return {
            isLoading: true,
        }
    },

    computed: {
        ...mapGetters(['themeMode']),
    },

    watch: {
        themeMode(value) {
            this.toggleThemeClass(value)
        },
    },

    mounted() {
        this.setLocalLoader()
    },

    created() {
        this.toggleThemeClass(this.themeMode)
    },

    methods: {
        toggleThemeClass(value) {
            if (value === 'dark') {
                document.querySelector('html').classList.remove('theme-light')
                document.querySelector('html').classList.add('theme-dark')
            } else {
                document.querySelector('html').classList.remove('theme-dark')
                document.querySelector('html').classList.add('theme-light')
            }
        },
        setLocalLoader() {
            EventBus.$on('i18n-load-start', () => (this.isLoading = true))

            EventBus.$on('i18n-load-complete', () => (this.isLoading = false))
        },
    },
}
</script>

<style lang="scss" src="@/assets/scss/main.scss"></style>
