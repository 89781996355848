<template>
    <div class="circles__circle animated fade-in" v-in-viewport="{ margin: '-100px 0px', once: true }" :style="style">
        <CirclesCircle v-if="numCircles > 2" :num-circles="numCircles - 1" :radius="radius - radius / numCircles" />
    </div>
</template>

<script>
export default {
    name: 'CirclesCircle',

    props: {
        numCircles: {
            type: Number,
            default: 0,
            required: true,
        },
        radius: {
            type: Number,
            default: 50,
            required: true,
        },
    },

    computed: {
        style() {
            return {
                height: `${Math.round(this.radius)}px`,
                width: `${Math.round(this.radius)}px`,
            }
        },
    },
}
</script>
