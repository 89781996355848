<template>
    <div class="section section_theme_security-list">
        <div class="container container_theme_default">
            <div class="section__row">
                <div class="section__col section__col_name_title">
                    <h1
                        class="section__title section__title_state_sticky animated fade-in-up"
                        v-in-viewport="{ margin: '-100px 0px', once: true }"
                    >
                        {{ $t(`${$options.name}.title`) }}
                    </h1>
                </div>
                <div class="section__col section__col_name_list">
                    <ul class="section__list">
                        <li
                            class="section__list-item animated fade-in-up"
                            v-in-viewport="{ margin: '-100px 0px', once: true }"
                        >
                            <a
                                class="section__list-link"
                                href="https://www.un.org/securitycouncil/content/un-sc-consolidated-list"
                                target="_blank"
                            >
                                {{ $t(`${$options.name}.united`) }}
                            </a>
                        </li>
                        <li
                            class="section__list-item animated fade-in-up"
                            v-in-viewport="{ margin: '-100px 0px', once: true }"
                        >
                            <a
                                class="section__list-link"
                                href="https://ec.europa.eu/info/business-economy-euro/banking-and-finance/international-relations/sanctions_en"
                                target="_blank"
                            >
                                {{ $t(`${$options.name}.european`) }}
                            </a>
                        </li>
                        <li
                            class="section__list-item animated fade-in-up"
                            v-in-viewport="{ margin: '-100px 0px', once: true }"
                        >
                            <a
                                class="section__list-link"
                                href="https://www.gov.uk/government/organisations/hm-treasury"
                                target="_blank"
                            >
                                {{ $t(`${$options.name}.hm`) }}
                            </a>
                        </li>
                        <li
                            class="section__list-item animated fade-in-up"
                            v-in-viewport="{ margin: '-100px 0px', once: true }"
                        >
                            <a class="section__list-link" href="http://www.fedsfm.ru/" target="_blank">
                                {{ $t(`${$options.name}.rosfinmonotoring`) }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeSecurityListSection',
}
</script>
