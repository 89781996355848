import Vue from 'vue'
import vuescroll from 'vuescroll'

Vue.use(vuescroll, {
    name: 'dmScroll',
    ops: {
        rail: {
            opacity: '0.2',
            background: '#cecece',
            border: undefined,
            size: '2px',
        },
        bar: {
            background: '#716F85',
            keepShow: true,
            size: '5px',
            minSize: 0.2,
        },
        scrollButton: {
            enable: true,
            background: '#cecece',
        },
        scrollPanel: {
            easing: 'easeInOutCubic',
            speed: 800,
            initialScrollY: false,
            initialScrollX: false,
            scrollingX: false,
            scrollingY: true,
        },
        vuescroll: {
            wheelScrollDuration: 0,
            wheelDirectionReverse: false,
            sizeStrategy: 'number',
        },
    },
})
