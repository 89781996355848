<template>
    <div :class="['accordion', `accordion_theme_${theme}`]">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'AppAccordion',

    props: {
        theme: {
            type: String,
            default: 'praso',
        },
    },
}
</script>
