const dateTimeFormats = {
    en: {
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        },
    },
    ar: {
        short: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        },
    },
}

export default dateTimeFormats
