<template>
    <div :class="['theme-switcher', `theme-switcher_location_${location}`, 'noselect']" @click="toggleThemeMode">
        <AppIcon
            class="theme-switcher__icon"
            :key="`${themeMode}`"
            :name="isThemeModeLight ? 'moon' : 'sun'"
            width="25"
            height="25"
            color="#AAA9BF"
        />
        <span class="theme-switcher__title">{{
            isThemeModeLight ? $t(`${$options.name}.dark`) : $t(`${$options.name}.light`)
        }}</span>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'ThemeSwitcher',

    props: {
        location: {
            default: 'body',
            type: String,
        },
    },

    computed: {
        ...mapGetters(['themeMode', 'isThemeModeLight']),
    },

    methods: {
        ...mapActions({ toggleThemeMode: 'toggleThemeMode' }),
    },
}
</script>
