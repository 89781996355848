<template>
    <kinesis-container class="section section_theme_interface">
        <div class="container container_theme_default">
            <div class="section__row">
                <div class="section__col section__col_name_text">
                    <div
                        class="section__text-container animated fade-in-up"
                        v-in-viewport="{ margin: '-100px 0px', once: true }"
                    >
                        <h1 class="section__title">
                            {{ $t(`${$options.name}.title`) }}
                        </h1>
                        <div class="section__text">
                            {{ $t(`${$options.name}.content`) }}
                        </div>
                    </div>
                </div>
                <div class="section__col section__col_name_images">
                    <div class="section__images animated fade-in" v-in-viewport="{ margin: '-100px 0px', once: true }">
                        <div class="section__image-container">
                            <kinesis-element :strength="5">
                                <img
                                    class="section__image section__image_num_1"
                                    :src="require('@/assets/images/phones/phone-9.png')"
                                />
                            </kinesis-element>
                        </div>
                        <div class="section__image-container">
                            <kinesis-element :strength="15">
                                <img
                                    class="section__image section__image_num_2"
                                    :src="require('@/assets/images/phones/phone-8.png')"
                                />
                            </kinesis-element>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </kinesis-container>
</template>

<script>
export default {
    name: 'HomeInterfaceSection',
}
</script>
