import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted)

Vue.toasted.register('success', (payload) => payload.message, {
    closeOnSwipe: false,
    duration: 5000,
    containerClass: 'toasted-container_theme_brico',
    position: 'toasted-container_position_bottom-right',
    type: 'toasted_type_success',
    theme: 'toasted_theme_brico',
    icon: 'toasted__icon',
    iconPack: 'custom-class',
    action: {
        class: 'toasted__close',
        onClick: (e, toastObject) => toastObject.goAway(0),
    },
})

Vue.toasted.register('error', (payload) => payload.message, {
    closeOnSwipe: false,
    duration: 5000,
    containerClass: 'toasted-container_theme_brico',
    position: 'toasted-container_position_bottom-right',
    type: 'toasted_type_error',
    theme: 'toasted_theme_brico',
    icon: 'toasted__icon',
    iconPack: 'custom-class',
    action: {
        class: 'toasted__close',
        onClick: (e, toastObject) => toastObject.goAway(0),
    },
})
