const numberFormats = {
    en: {
        currency: {
            style: 'currency',
            currency: 'USD',
        },
    },
    ar: {
        currency: {
            style: 'currency',
            currency: 'USD',
            currencyDisplay: 'code',
        },
    },
}

export default numberFormats
