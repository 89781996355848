<template>
    <div
        :class="[
            'input-field',
            `input-field_type_textarea`,
            `input-field_theme_${theme}`,
            `input-field_name_${name}`,
            {
                'input-field_state_focus': isFocus,
                'input-field_state_disabled': isDisabled,
                'input-field_state_invalid': !!$slots.error,
            },
        ]"
    >
        <label v-if="label" class="input-field__label" :for="id">
            {{ label }}
        </label>
        <div class="input-field__input-wrapper">
            <textarea
                v-bind="privateProps"
                class="input-field__input"
                v-autofocus="autofocus"
                @focus="handleFocus"
                @blur="handleBlur"
                @input="handleInput"
                ref="input"
            />
            <div v-if="$slots.postfix" class="input-field__postfix">
                <slot name="postfix" />
            </div>

            <div v-if="$slots.hint" class="input-field__hint input-field-hint">
                <AppIcon class="input-field-hint__icon" name="question" :width="27" :height="27"></AppIcon>
                <div class="input-field-hint__message">
                    <slot name="hint" />
                </div>
            </div>
        </div>

        <div v-if="$slots.description" class="input-field__description">
            <slot name="description" />
        </div>

        <transition name="fade" mode="out-in">
            <div v-if="$slots.error" class="input-field__error">
                <slot name="error" />
            </div>
        </transition>
    </div>
</template>

<script>
const randomInt = () => {
    const s4 = () =>
        Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1)

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

export default {
    name: 'TextAreaField',

    props: {
        theme: {
            type: String,
            default: 'krudon',
        },
        label: {
            type: String,
            default: '',
        },
        rows: {
            type: String,
            default: '3',
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Number],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        id: {
            type: [Number, String],
            default: () => randomInt(),
        },
    },

    data: () => ({
        isFocus: false,
    }),

    computed: {
        isDisabled() {
            return this.disabled
        },
        privateProps() {
            return {
                ...this.$attrs,
                ...this.$props,
                id: this.id,
            }
        },
    },

    watch: {
        autofocus(val) {
            if (val) {
                this.$refs.input.focus()
            }
        },
    },

    methods: {
        handleFocus() {
            this.isFocus = true

            this.$emit('focus')
        },
        handleBlur() {
            this.isFocus = false

            this.$emit('blur')
        },
        handleInput(e) {
            this.$emit('input', e.target.value)
        },
    },
}
</script>
