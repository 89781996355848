<template>
    <div class="circles" :class="`circles_theme_${theme}`">
        <CirclesCircle :num-circles="8" :radius="innerWidth" />
    </div>
</template>

<script>
import CirclesCircle from './Circle'

export default {
    name: 'Circles',

    components: {
        CirclesCircle,
    },

    props: {
        theme: {
            type: String,
            default: 'default',
        },
    },

    data: () => ({
        innerWidth: 1000,
        timeout: null,
        debounce: 200,
    }),

    created() {
        window.addEventListener('resize', this.getWindowWidth)

        this.getWindowWidth()
    },

    destroyed() {
        this.$el.removeEventListener('resize', () => this.getWindowWidth)
    },

    methods: {
        getWindowWidth() {
            if (!this.debounce) {
                if (window.innerWidth < 960) {
                    this.innerWidth = window.innerWidth * 8
                } else {
                    this.innerWidth = window.innerWidth
                }

                return
            }

            if (this.timeout) {
                clearTimeout(this.timeout)
            }

            this.timeout = setTimeout(() => {
                if (window.innerWidth < 960) {
                    this.innerWidth = window.innerWidth * 2
                } else {
                    this.innerWidth = window.innerWidth
                }
            }, this.debounce)
        },
    },
}
</script>
