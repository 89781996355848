export default {
    europe: [
        'rus',
        'deu',
        'fra',
        'gbr',
        'ita',
        'esp',
        'pol',
        'rou',
        'kaz',
        'nld',
        'bel',
        'grc',
        'prt',
        'cze',
        'hun',
        'swe',
        'aze',
        'blr',
        'aut',
        'che',
        'bgr',
        'srb',
        'dnk',
        'fin',
        'svk',
        'nor',
        'geo',
        'irl',
        'hrv',
        'bih',
        'mda',
        'arm',
        'ltu',
        'mkd',
        'svn',
        'lva',
        'unk',
        'est',
        'cyp',
        'mne',
        'lux',
        'mlt',
        'isl',
        'jey',
        'imn',
        'and',
        'ggy',
        'mco',
        'fro',
        'smr',
        'gib',
        'ala',
        'sjm',
        'vat',
    ],
    'near-east': ['tur', 'irq', 'sau', 'yem', 'irn', 'are', 'isr', 'jor', 'bhr', 'lbn', 'omn', 'kwt', 'qat'],
    africa: [
        'nga',
        'eth',
        'egy',
        'cog',
        'zaf',
        'tza',
        'ken',
        'dza',
        'uga',
        'sdn',
        'mar',
        'gha',
        'moz',
        'civ',
        'ago',
        'cmr',
        'ner',
        'bfa',
        'mli',
        'mwi',
        'zmb',
        'sen',
        'zwe',
        'tcd',
        'gin',
        'tun',
        'rwa',
        'ssd',
        'ben',
        'som',
        'bdi',
        'sle',
        'caf',
        'eri',
        'cod',
        'lbr',
        'mrt',
        'gab',
        'nam',
        'bwa',
        'lso',
        'gnq',
        'gmb',
        'gnb',
        'mus',
        'swz',
        'reu',
        'com',
        'esh',
        'cpv',
        'myt',
        'stp',
        'syc',
    ],
}
