<template>
    <div :class="['market-links', `market-links_location_${location}`]">
        <div class="market-links__title">{{ $t(`${$options.name}.download`) }}</div>
        <div class="market-links__links">
            <a href="https://apps.apple.com/app/id1528670907" class="market-links__link" target="_blank">
                <AppIcon name="apple" :width="16" :height="16"></AppIcon>
            </a>
            <a
                href="https://play.google.com/store/apps/details?id=com.directmoney.directmoney"
                class="market-links__link"
                target="_blank"
            >
                <AppIcon name="android" :width="16" :height="16"></AppIcon>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MarketLinks',

    props: {
        location: {
            default: 'body',
            type: String,
        },
    },
}
</script>
