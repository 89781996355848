<template>
    <kinesis-container class="section section_theme_security">
        <div class="container container_theme_default">
            <div class="section__row">
                <div class="section__col section__col_name_text">
                    <div
                        class="section__text-container animated fade-in-up"
                        v-in-viewport="{ margin: '-100px 0px', once: true }"
                    >
                        <h1 class="section__title">
                            {{ $t(`${$options.name}.title.firstLine`) }}
                            <br />
                            {{ $t(`${$options.name}.title.secondLine`) }}
                        </h1>
                        <div class="section__text">
                            {{ $t(`${$options.name}.content`) }}
                        </div>
                    </div>
                </div>
                <div class="section__col section__col_name_image">
                    <kinesis-element class="section__image-container" :strength="5">
                        <img
                            class="section__image animated fade-in"
                            v-in-viewport="{ margin: '-100px 0px', once: true }"
                            :src="require('@/assets/images/phones/phone-7.png')"
                        />
                    </kinesis-element>
                </div>
            </div>
        </div>
    </kinesis-container>
</template>

<script>
export default {
    name: 'HomeSecuritySection',
}
</script>
