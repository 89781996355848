<template>
    <div :class="['header', { header_mode_sticky: sticky }]">
        <div class="container container_theme_default">
            <div class="header__main">
                <router-link class="header__logo" to="/">
                    <AppIcon class="header__logo-img" name="logo" width="164" height="44" color="#038cff" />
                </router-link>
                <ul class="header__menu header-menu">
                    <li class="header-menu__item header-menu__item_name_market-links">
                        <MarketLinks location="header" />
                    </li>
                    <li class="header-menu__item header-menu__item_name_language-switcher">
                        <LanguageSwitcher />
                    </li>
                    <li class="header-menu__item header-menu__item_name_theme-switcher">
                        <ThemeSwitcher location="header" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import MarketLinks from '@/components/MarketLinks'
import LanguageSwitcher from '@/components/LanguageSwitcher'
import ThemeSwitcher from '@/components/ThemeSwitcher'

export default {
    name: 'AppHeader',

    components: {
        MarketLinks,
        LanguageSwitcher,
        ThemeSwitcher,
    },

    props: {
        sticky: {
            default: false,
            type: Boolean,
        },
    },
}
</script>
